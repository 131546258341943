import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import swal from "sweetalert";
import "./login.css";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
    }, [login, navigate]);
    const onSubmit = async (data) => {
        if (data.username !== "administrator") {
            if (data.username.length < 6) {
                setError("username", {
                    type: "minLength",
                    message: "Tên đăng nhập không hợp lệ"
                });
                return;
            }
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu không hợp lệ"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
            .then((res) => {
                localStorage.setItem("user", res.data.data);
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    navigate("/");
                });
            })
            .catch((err) => setErr("Tên đăng nhập hoặc mật khẩu không chính xác"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="logo-login">
                <Slide arrows={false} autoplay>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike1.png")} alt="" />
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike2.png")} alt="" />
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike3.png")} alt="" />
                    </div>
                </Slide>

            </div>
            <div className="app123">
                <div className="bg"></div>

                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Đăng nhập</h1>
                    <div className="inputs">
                        <div>
                            <input
                                type="text"
                                className="ip-lg"
                                {...register("username", { required: true })}
                                placeholder="Nhập tên tài khoản của bạn"
                            />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Nhập mật khẩu của bạn" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#333" }} /> : <Visibility sx={{ color: "#333" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <p className="p-lg">
                        <Link className="a-lg" to="https://direct.lc.chat/16952547">
                            Bạn quên mật khẩu? Hãy ấn vào đây
                        </Link>
                    </p>
                    <button className="btn-lg" type="submit">
                        Đăng nhập
                    </button>
                    <button className="btn-lg" type="button" style={{ marginTop: "10px" }} onClick={() => navigate("/register")}>
                        Bạn chưa có tài khoản? Đăng ký ngay
                    </button>
                </form>
                <div className="dongtaitro">
                    <div className="dongtaitroboi">
                        <div><img src={require("../../img/vin.png")} alt="" /></div>
                        <div><img src={require("../../img/lotte.png")} alt="" /></div>
                        <div><img src={require("../../img/meta.png")} alt="" style={{ padding: "28px" }} /></div>
                        <div><img src={require("../../img/agribank.png")} alt="" /></div>
                        <div><img src={require("../../img/dautu.png")} alt="" /></div>
                        <div><img src={require("../../img/vietcom.png")} alt="" /></div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Login;
