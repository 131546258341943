import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./request.css";

import { Box, Button, Container, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import { useEffect, useState } from "react";
function HistoryBetAll() {
    const [data, setData] = useState(null);
    const [searched, setSearched] = useState("");
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [profile, setProfile] = useState(null);

    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a = row?.user?.username?.toString().toLowerCase().includes(searchedVal.toString());
                const b = (Number(row?.user?.iduser) + 6000).toString().includes(searchedVal.toString());
                return a || b;
            });
            setData(filteredRows);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/all`, {})
            .then((res) => {
                localStorage.setItem("data", JSON.stringify(res.data.data));
                setData(res.data.data);
            })
            .catch((res) => setData(null));
    }, []);
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };
    const [st, setSt] = useState(0);

    const handleChangeStatus = (e) => {
        if (ls.status_bet === "Lose" || ls.status_bet === "Pending") {
            if (e.target.value === "Win") {
                setSt(1);
            }
        } else {
            if (e.target.value === "Lose" || e.target.value === "Pending") {
                setSt(2);
            }
        }
        setLs((prevLs) => ({
            ...prevLs,
            status_bet: e.target.value
        }));
    };
    const handleChangeChoose = (e) => {
        setLs((prevLs) => ({
            ...prevLs,
            bet: e.target.value
        }));
    };
    const handleSubmitLs = (e) => {
        e.preventDefault();
        const formData = {
            id: ls._id,
            userId: ls.user._id,
            status_bet: e.target.status_bet.value,
            money: Number(e.target.money.value) ? Number(e.target.money.value) : Number(ls.money),
            bet: Number(e.target.bet.value) ? Number(e.target.bet.value) : Number(ls.bet)
        };
        axios
            .patch(`${process.env.REACT_APP_API_URL}/history`, formData)
            .then((res) => {
                setShow(false);
                swal("Cập nhật thành công").then((value) => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                setShow(false);
                swal("Có lỗi vui lòng thử lại!");
            });
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">Lịch sử tham gia</div>
                                <div className="form_set">
                                    <Box sx={{ minWidth: 1000 }}>
                                        <TextField
                                            value={searched}
                                            onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                            placeholder="Tìm kiếm"
                                            sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                        />
                                        <Table sx={{ width: 1200 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>STT</TableCell>
                                                    <TableCell>User</TableCell>
                                                    <TableCell>ID User</TableCell>
                                                    <TableCell>Level</TableCell>
                                                    <TableCell>Số kỳ</TableCell>
                                                    <TableCell>Người chơi đặt</TableCell>
                                                    <TableCell>Số điểm</TableCell>
                                                    <TableCell>Trạng thái</TableCell>
                                                    <TableCell>Thời gian</TableCell>
                                                    <TableCell>Sửa</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {data != null ? (
                                                <TableBody>
                                                    {data?.slice((pages - 1) * 30, (pages - 1) * 30 + 30).map((item, index) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600" }}>{index + 1}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600" }}>{item.user.username}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600" }}>{Number(item.user.iduser) + 6000}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600" }}>Level {item.id_bet.level}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600" }}>{item.id_bet.id_bet}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600" }}>
                                                                    {item?.bet === 1 ? "SIZE S" : item?.bet === 2 ? "SIZE M" : item?.bet === 3 ? "SIZE L" : item?.bet === 4 ? "SIZE XL" : "Lỗi"}
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: "600" }}>{item.money}</TableCell>
                                                                {item.status_bet === "Win" ? (
                                                                    <TableCell sx={{ fontWeight: "600" }}>
                                                                        <Button color="success">{item.status_bet}</Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                {item.status_bet === "Lose" ? (
                                                                    <TableCell sx={{ fontWeight: "600" }}>
                                                                        <Button color="error">{item.status_bet}</Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                {item.status_bet === "Pending" ? (
                                                                    <TableCell sx={{ fontWeight: "600" }}>
                                                                        <Button color="warning">{item.status_bet}</Button>
                                                                    </TableCell>
                                                                ) : null}
                                                                <TableCell sx={{ fontWeight: "600" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                                <TableCell sx={{ padding: "10px" }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setShow(true);
                                                                            setLs(item);
                                                                        }}>
                                                                        Sửa
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <div>Đang cập nhật</div>
                                            )}
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 30) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    }

                    {isShow === true ? (
                        <>
                            <div className="modal">
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    <form onSubmit={handleSubmitLs}>
                                        <div className="modalinner">
                                            <div className="modalheader"> Sửa lịch sử </div>

                                            <div className="modalform">
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Người chơi: </div>
                                                    <div>
                                                        <b>{ls.user.username}</b>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Lựa chọn: </div>
                                                    <div>
                                                        <select id="bet" name="bet" value={ls.bet} onChange={handleChangeChoose}>
                                                            <option value="1" selected={ls.bet === 1}>
                                                                SIZE S
                                                            </option>
                                                            <option value="2" selected={ls.bet === 2}>
                                                                SIZE M
                                                            </option>
                                                            <option value="3" selected={ls.bet === 3}>
                                                                SIZE L
                                                            </option>
                                                            <option value="4" selected={ls.bet === 4}>
                                                                SIZE XL
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số điểm cược: </div>
                                                    <TextField name="money" defaultValue={ls.money} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Trạng thái: </div>
                                                    <div>
                                                        <select name="status_bet" value={ls.status_bet} onChange={handleChangeStatus}>
                                                            <option value="Win" selected={ls.status_bet === "Win"} style={{ color: "#14B8A6" }}>
                                                                Win
                                                            </option>
                                                            <option value="Lose" selected={ls.status_bet === "Lose"} style={{ color: "#D14343" }}>
                                                                Lose
                                                            </option>
                                                            <option value="Pending" selected={ls.status_bet === "Pending"} style={{ color: "#FFB020" }}>
                                                                Pending
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="item_btn_form">
                                                <div className="modalformcontrols">
                                                    <Button type="submit">XÁC NHẬN</Button>
                                                </div>
                                                <div className="modalformcontrols">
                                                    <Button onClick={() => setShow(false)}>ĐÓNG</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : null}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default HistoryBetAll;
