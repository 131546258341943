import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import swal from "sweetalert";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./login.css";

function Register1() {
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (login) {
			navigate("/");
		}
	}, []);
	const onSubmit = (data) => {
		const regex = /^[a-zA-Z0-9]+$/;
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Tên đăng nhập tối thiểu 6 kí tự",
			});
			return;
		}
		if (!regex.test(data.username)) {
			setError("username", {
				type: "minLength",
				message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt",
			});
			return;
		}
		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Mật khẩu tối thiểu 6 kí tự",
			});
			return;
		}
		if (data.password !== data.ippassword) {
			setError("ippassword", {
				type: "minLength",
				message: "Nhập lại mật khẩu không chính xác",
			});
			return;
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
				username: data.username.toLowerCase(),
				password: data.password,
				ippassword: data.ippassword,
			})
			.then((res) => {
				swal({
					title: "Thông báo",
					text: "Đăng ký thành công",
					icon: "success",
					buttons: "OK",
				}).then(() => {
					axios
						.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
						.then((res) => {
							localStorage.setItem("user", res.data.data);
							navigate("/");
						});
				});
			})
			.catch((err) => {
				setErr(
					err.response.data.message ? err.response.data.message : err.message
				);
			});
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const [showPassword0, setShowPassword0] = useState(false);
	const toggleShowPassword0 = () => {
		setShowPassword0(!showPassword0);
	};
	return (
		<>
			<div className="logo-login">
				<Slide arrows={false} autoplay>
					<div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
						<img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike1.png")} alt="" />
					</div>
					<div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
						<img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike2.png")} alt="" />
					</div>
					<div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
						<img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike3.png")} alt="" />
					</div>
				</Slide>

			</div>
			<div className="app123">
				<div className="bg"></div>

				<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
					<h1>Đăng ký</h1>
					<div className="inputs">
						<div>
							<input
								type="text"
								{...register("username", { required: true })}
								className="ip-lg"
								placeholder="Nhập tên tài khoản của bạn"
							/>
							{errors.username ? <p>{errors.username.message}</p> : null}
						</div>
						<div>
							<input type={showPassword0 ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Nhập mật Khẩu của bạn" />
							<div onClick={toggleShowPassword0} className="change-visible">
								{showPassword0 ? <VisibilityOff sx={{ color: "#eee" }} /> : <Visibility sx={{ color: "#eee" }} />}
							</div>
							{errors.password ? <p>{errors.password.message}</p> : null}
						</div>
						<div>
							<input type={showPassword ? "text" : "password"} className="ip-lg" {...register("ippassword", { required: true })} placeholder="Nhập lại mật khẩu" />
							<div onClick={toggleShowPassword} className="change-visible">
								{showPassword ? <VisibilityOff sx={{ color: "#eee" }} /> : <Visibility sx={{ color: "#eee" }} />}
							</div>
							{errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
						</div>
					</div>
					{err ? <p style={{ color: "red" }}>{err}</p> : null}
					<p className="p-lg">
						<Link className="a-lg" to="/login">
							Đã có tài khoản ? Đăng nhập ngay
						</Link>
					</p>
					<button type="submit" className="btn-lg">
						Đăng ký
					</button>
				</form>
				<div className="dongtaitro">
					<div className="dongtaitroboi">
						<div><img src={require("../../img/vin.png")} alt="" /></div>
						<div><img src={require("../../img/lotte.png")} alt="" /></div>
						<div><img src={require("../../img/meta.png")} alt="" style={{ padding: "28px" }} /></div>
						<div><img src={require("../../img/agribank.png")} alt="" /></div>
						<div><img src={require("../../img/dautu.png")} alt="" /></div>
						<div><img src={require("../../img/vietcom.png")} alt="" /></div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Register1;
