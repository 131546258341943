import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./login.css";

function AdminLogin() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/admin");
        }
    }, [login, navigate]);
    const onSubmit = async (data) => {
        if (data.username !== "administrator") {
            if (data.username.length < 6) {
                setError("username", {
                    type: "minLength",
                    message: "Tên đăng nhập không hợp lệ"
                });
                return;
            }
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu không hợp lệ"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
            .then((res) => {
                const btoken = res.data.data;
                axios
                    .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {
                        headers: {
                            'Authorization': `Bearer ${btoken}`,
                        },
                    })
                    .then((rs) => {
                        if (rs.data.data.isAdmin) {
                            localStorage.setItem("user", btoken);
                            swal({
                                title: "Thông báo",
                                text: "Đăng nhập thành công",
                                icon: "success",
                                buttons: "OK"
                            }).then(() => {
                                navigate("/admin");
                            });
                        } else {
                            swal({
                                title: "Thông báo",
                                text: "Bạn không có đủ thẩm quyền!",
                                icon: "error",
                                buttons: "OK"
                            })
                            localStorage.removeItemItem("user");
                        }
                    })
                    .catch((err) => {
                        swal({
                            title: "Thông báo",
                            text: "Có lỗi trong quá trình đăng nhập!",
                            icon: "error",
                            buttons: "OK"
                        })
                        localStorage.removeItem("user");
                    });
            })
            .catch((err) => setErr("Tên đăng nhập hoặc mật khẩu không chính xác"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="app123" style={{ height: "100vh" }}>
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Đăng nhập quản trị</h1>
                    <div className="inputs">
                        <div>
                            <input
                                type="text"
                                className="ip-lg"
                                {...register("username", { required: true })}
                                placeholder="Nhập tài khoản quản trị"
                            />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Nhập mật khẩu của bạn" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#333" }} /> : <Visibility sx={{ color: "#333" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <button className="btn-lg" type="submit">
                        Đăng nhập
                    </button>

                </form>
            </div>
        </>
    );
}
export default AdminLogin;
