import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import { Slide } from 'react-slideshow-image';

function Game1() {
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [err, setErr] = useState(null);
    const [active, setActive] = useState(null);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [state, setState] = useState(null);
    const [total, setTotal] = useState(null);
    const [isShow, setShow] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [choose, setChoose] = useState(null);
    const [quantity, setQuantity] = useState(100);
    const [tabActive, setTabActive] = useState(1);

    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (start === false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            axios.get(`${process.env.REACT_APP_API_URL}/bet1/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(new Date(res.data.data.createdAt));
                setStart(true);
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/bet1/getallbet`, {})
                .then((res) => {
                    setTotal(res.data.data);
                })
                .catch(() => setTotal(null));
        }
    }, [start]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
                if (res?.data.data[0]) {
                    setVisible({
                        money: res?.data?.data[0]?.money.toLocaleString(),
                        id: res?.data?.data[0]?._id,
                        msg: res?.data?.data[0]?.msg
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            setShowConfetti(true);
            swalInst = swal({
                title: "Xin chúc mừng !!!",
                text: `Chúc mừng quý khách mang ID ${Number(profile?.iduser) + 6000
                    } đã trúng giải thưởng ngẫu nhiên trị giá ${data.money.toLocaleString()} VNĐ. Vui lòng liên hệ CSKH để biết thêm chi tiết.`,
                //text: data.msg,
                className: "bg-gift",
                //icon: GiftImg,
                buttons: {
                    submit: "Đồng ý"
                }
            }).then(() => {
                setShowConfetti(false);
                axios.post("https://server.nikevn-shop.com/notification/seen", {
                    id: data.id
                });
            });
            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);
    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);

        let myTimeout;
        // if (curTime_second <= 0) {
        // 	axios.get(`${process.env.REACT_APP_API_URL}//bet/get`).then((res) => {
        // 		let date = new Date();
        // 		let date1 = new Date(res.data.data[0].createdAt);
        // 		if (date - date1 > 60000) {
        // 			setBet(null);
        // 		} else {
        // 			setBet(res.data.data[0]);
        // 			setDulieunhap(new Date(res.data.data[0].createdAt));
        // 		}
        // 	});
        // }

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const onChoose = (e) => {
        setChoose(e);
        setActive(e);
        setShowPopup(true);
    };
    const onSubmit = async (data) => {
        if (quantity == 0) {
            swal("Thông báo", "Vui lòng nhập số tiền", "info");
            return;
        }
        if (!choose) {
            swal("Thông báo", "Vui lòng chọn ô", "warning");
            return;
        }
        const formDate = {
            id: bet._id,
            money: quantity,
            bet: choose
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/history1/choose`, formDate)
            .then((res) => {
                swal("Thành công", "Nhập thành công", "success");
                setStart(false);
                setErr(res.data.message);
                setShowPopup(false);
            })
            .catch((res) => swal("error", res.response.data.message, "error"));

        resetForm();
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const handleSetValue = (value) => {
        setQuantity(value);
    };

    const resetForm = () => {
        setQuantity(100);
        setActive(null);
        setState(null);
        setShowPopup(false);
    };

    return (
        <>
            <div className="app1" style={{ paddingBottom: "60px" }}>
                <div className="header">
                    <img style={{ height: "40px" }} src={require("../../img/logo.png")} alt="" />
                </div>
                <div style={{ background: "#111", padding: "10px 0" }}>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <section data-v-82095748="" data-v-0f9d40fc="" className="lottery-timer theme-main-bg-5 theme-after-border-color-1" data-v-69b3b4a1="">

                            <div data-v-82095748="" className="content-box">
                                <div>
                                    ID: {Number(profile?.iduser) + 6000}
                                </div>
                                <div>
                                    Số dư: {Number(profile?.money)}
                                </div>
                                <div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                    Number: <span className="sid"> {bet ? bet.id_bet : "----"}</span>
                                </div>
                                <div data-v-82095748="" className="content theme-color-white">
                                    <span data-v-82095748=""></span>
                                    <span data-v-82095748="" className="time">
                                        00
                                    </span>
                                    <span data-v-82095748="" className="time shengtime1">
                                        0{minute}
                                    </span>
                                    <span data-v-82095748="" className="time shengtime2">
                                        {second < 10 ? "0" : ""}
                                        {second}
                                    </span>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
                <div className="record_bet">
                    <div data-v-4a0db828="" className="lottery-result">
                        <div className="bet__title">
                            <div>NUMBER: <span className="sid_ed">{total ? total[0]?.id_bet : "----"}</span></div>

                            <div className="info-icon" onClick={openPopup}>
                                <InfoIcon />
                            </div>
                        </div>
                        <div data-v-4a0db828="" className="content">
                            {total != null && (
                                <ul data-v-4a0db828="" className="clear-fix list">
                                    <li data-v-4a0db828="" className="num-item ball   i0 number1 numberlist">
                                        {total[0].result.split(" ")[0]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i1 number2 numberlist">
                                        {total[0].result.split(" ")[1]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i2 number3 numberlist">
                                        {total[0].result.split(" ")[2]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i3 number4 numberlist">
                                        {total[0].result.split(" ")[3]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i4 number5 numberlist">
                                        {total[0].result.split(" ")[4]}
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>

                    <div className="bet__title">
                        Bảng phân phối công việc
                    </div>

                    <nav style={{ marginBottom: "10px" }} data-v-260e3e17="" data-v-0f9d40fc="" className="tab-box lottery-game-tab" data-v-435dc89a="">
                        <div data-v-260e3e17="" className={tabActive === 0 ? "tab-item tab-item-active" : "tab-item"} onClick={() => { setTabActive(0); resetForm(); }}>
                            THỜI TRANG
                        </div>
                        <div data-v-260e3e17="" className={tabActive === 1 ? "tab-item tab-item-active" : "tab-item"} onClick={() => { setTabActive(1); resetForm(); }}>
                            SẢN PHẨM
                        </div>
                        <div data-v-260e3e17="" className={tabActive === 2 ? "tab-item tab-item-active" : "tab-item"} onClick={() => { setTabActive(2); resetForm(); }}>
                            KHÁCH HÀNG
                        </div>
                    </nav>

                    {
                        tabActive === 0 &&

                        <div className="tab0">
                            <Slide arrows autoplay>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                                    <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike3.png")} alt="" />
                                    <div className="tabtext">
                                        Nike là một trong những thương hiệu thời trang thể thao cao cấp lớn nhất thế giới hiện nay. Cùng với các thiết bị thể thao danh tiếng, địa vị của thương hiệu Nike trên thị trường thế giới khó có thể lung lay trong tương lai. Thương hiệu này phát triển đa dạng trong nhiều lĩnh vực như: Trang thiết bị thể thao, quần áo và giày dép phục vụ các môn thể thao, dịch vụ tương tác liên quan đến thể thao. Đặc biệt, Nike luôn ứng dụng khoa học công nghệ cao vào các sản phẩm của hãng.
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                                    <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike4.png")} alt="" />

                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                                    <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike5.png")} alt="" />

                                </div>
                            </Slide>
                        </div>
                    }

                    {
                        tabActive === 1 &&
                        <div className="tab1">
                            <ul data-v-0f9d40fc="" className="lottery-code-panel-body">
                                <li data-v-0f9d40fc="" className="ybcp-num-item">
                                    <span
                                        data-v-0f9d40fc=""
                                        className={`lottery-code-item-name ${active == 1 && "active"}`}
                                        onClick={(e) => {
                                            onChoose(1);
                                        }}>
                                        SIZE S
                                    </span>
                                </li>
                                <li data-v-0f9d40fc="" className="ybcp-num-item">
                                    <span
                                        data-v-0f9d40fc=""
                                        className={`lottery-code-item-name ${active == 2 && "active"}`}
                                        onClick={(e) => {
                                            onChoose(2);
                                        }}>
                                        SIZE M
                                    </span>
                                </li>
                                <li data-v-0f9d40fc="" className="ybcp-num-item">
                                    <span
                                        data-v-0f9d40fc=""
                                        className={`lottery-code-item-name ${active == 3 && "active"}`}
                                        onClick={(e) => {
                                            onChoose(3);
                                        }}>
                                        SIZE L
                                    </span>
                                </li>
                                <li data-v-0f9d40fc="" className="ybcp-num-item">
                                    <span
                                        data-v-0f9d40fc=""
                                        className={`lottery-code-item-name ${active == 4 && "active"}`}
                                        onClick={(e) => {
                                            onChoose(4);
                                        }}>
                                        SIZE XL
                                    </span>
                                </li>
                            </ul>
                            <div>
                                <Slide arrows autoplay>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike1.png")} alt="" />
                                    </div>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike2.png")} alt="" />
                                    </div>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <img style={{ height: "300px", width: "600px !important" }} src={require("../../img/slidenike3.png")} alt="" />
                                    </div>
                                </Slide>
                            </div>

                        </div>
                    }

                    {
                        tabActive === 2 &&
                        <div className="tab2">
                            <marquee direction="up" height="300" className="con scroolbox">
                                <div>
                                    <div className="name">
                                        <h4>Chị Thanh</h4>
                                    </div>
                                    <div className="message">
                                        <p>Mua về dùng gần hết rồì mới đánh giá. Bỉm merries thì khỏi nói rồi. Hút thâm tốt thật.Cũng cảm ơn Merries đã tạo công ăn việc làm cho mọi người.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Hòa</h4>
                                    </div>
                                    <div className="message">
                                        <p>Tả này e bé dùng rất thích. Thấm hút tốt. Ko quá dày. Các mẹ nên lựa chọn tả merries này nhé.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Minh</h4>
                                    </div>
                                    <div className="message">
                                        <p>Thương hiệu uy tín. Sản phẩm chính hãng nên yên tâm tin dùng.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Trâm</h4>
                                    </div>
                                    <div className="message">
                                        <p>Mình mua dùnng thử tả Merries lần đầu nên chưa đánh giá được về công dụng tuy nhiên đây là nhãn hiệu tả tốt trong số các nhãn tả nên mình khá yên tâm. </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Lâm</h4>
                                    </div>
                                    <div className="message">
                                        <p>Bao bì khá xinh, chi phí cũng ổn.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Tùng</h4>
                                    </div>
                                    <div className="message">
                                        <p>Tã merries thấm hút tốt, bé đi tè xong sờ tã vẫn khô. Viền tã mềm mịn không làm cấn da em bé. Miếng dán dính chắc.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Ngọc</h4>
                                    </div>
                                    <div className="message">
                                        <p>Công việc khá đơn giản phù hợp với mẹ bỉm, ở nhà chăm con vẫn có công việc kiếm thêm ngoài ra còn có các phần quà bỉm rất ý nghĩa. Cảm ơn Merriess Việt Nam.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Linh</h4>
                                    </div>
                                    <div className="message">
                                        <p>Đánh giá 5 * cho web và nhãn hàng Merries!</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Nguyên</h4>
                                    </div>
                                    <div className="message">
                                        <p>Ban đầu chưa biết đến Merries nhưng qua thời gian làm việc và có những phần quà bỉm để xử dụng mình thấy nhãn hàng rất uy tín công việc cũng tốt.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị My</h4>
                                    </div>
                                    <div className="message">
                                        <p>Cảm ơn web cũng như bỉm Merries đã tạo ra công việc cho mọi người!</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Uyên</h4>
                                    </div>
                                    <div className="message">
                                        <p>Ban đầu cũng ngờ ngờ nhưng theo làm cũng được mấy tháng rồi rất ổn định và tốt. Cái tên nói lên tất cả Merries tuyệt vời.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Hoa</h4>
                                    </div>
                                    <div className="message">
                                        <p>Vẫn luôn tin dùng Merries cho cả bé lớn và bé hiện tại! Mềm, mỏng, dùng mùa hè đúng chuẩn luôn.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Thảo</h4>
                                    </div>
                                    <div className="message">
                                        <p>Hàng đẹp mà thấm hút tốt. Ngoài ra còn tạo công việc thụ động cho rất nhiều cảm bơn Babymart và Merries!</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Ngọc</h4>
                                    </div>
                                    <div className="message">
                                        <p>Công việc uy tín ổn định lâu dài, mấy người nhà e đều tham gia làm và có thêm khoản thu nhập. Cảm ơn công ty ạ!</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Chi</h4>
                                    </div>
                                    <div className="message">
                                        <p>Đã tham gia làm công việc thấy cũng đơn giản và kiếm được tiền thật. Cảm ơn Babymart.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Hiếu</h4>
                                    </div>
                                    <div className="message">
                                        <p>Bỉm dùng thấm hút tương đối thích bé không bị hăm và ngủ ngon giấc.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Nam</h4>
                                    </div>
                                    <div className="message">
                                        <p>Đánh giá nhãn hàng và web 5 *</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Hải</h4>
                                    </div>
                                    <div className="message">
                                        <p>Cảm ơn về công việc và những phần quà bỉm giá trị. Đã tạo ra công ăn việc làm còn có quà nhận về mãi yêu Merries !</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Tâm</h4>
                                    </div>
                                    <div className="message">
                                        <p>Cảm ơn các bạn nhờ các bạn tôi một người không có việc làm ở nhà chăm con giờ đã có thể có thêm thu nhập trang trải cuộc sống !</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Vui</h4>
                                    </div>
                                    <div className="message">
                                        <p>Luôn tin tưởng dùng bỉm merries từ bé đầu đến bé thứ 2. Chất lượng tốt dễ sử dụng. Mọi người nên dùng.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Huyền</h4>
                                    </div>
                                    <div className="message">
                                        <p>Nếu ai chưa biết vê merries thi nên lên google tìm hiểu nhé. Dùng rồi thì khỏi cần bàn về chất lượng cũng như giá thành.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Sơn</h4>
                                    </div>
                                    <div className="message">
                                        <p>Lúc đầu cũng sợ này sợ nọ theo babymart làm mấy tháng rồi cảm giác rất an toàn và yên tâm. Hãng merries cũng thật sự tốt.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Lương</h4>
                                    </div>
                                    <div className="message">
                                        <p>Hàng chính hãng, khả năng thấm hút tốt, mình mua nhiều lần rồi và sẽ tiếp tục ủng hộ ạ. Hàng thì chất lượng, cảm ơn merries nhiều nhiều nha.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Khuê</h4>
                                    </div>
                                    <div className="message">
                                        <p>Lúc đầu cũng không biết thông tin về hãng giờ thì vừa có việc làm vừa có thu nhập. Cảm ơn babymart!</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Mạnh</h4>
                                    </div>
                                    <div className="message">
                                        <p>Cảm ơn babymart và merries !</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Bảo</h4>
                                    </div>
                                    <div className="message">
                                        <p>Cảm ơn các bạn đã tạo công ăn việc làm cho rất nhiều người như tôi. Chúc các bạn ngày càng phát triển và thành công !</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Nhung</h4>
                                    </div>
                                    <div className="message">
                                        <p>Đã tham gia công việc được 1 tuần rất oke. Công việc đơn giản hiệu quả. Đã rủ thêm bạn bè vào làm kiếm thêm cảm ơn babymart và bỉm merries.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Chị Trà</h4>
                                    </div>
                                    <div className="message">
                                        <p>Đang thất nghiệp thì được giới thiệu công việc. Cũng sợ lừa nhưng nghe review rất tốt nên cũng tham gia đó tới giờ gần 1 tháng rồi. Cảm ơn meriesss.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="name">
                                        <h4>Anh Tiến</h4>
                                    </div>
                                    <div className="message">
                                        <p>Tã bỉm Merries chất lượng tốt, dễ sử dụng và chất liệu thân thiện cho da bé. Công việc uy tín lâu dài.</p>
                                    </div>
                                </div>
                            </marquee>
                        </div>
                    }

                </div>
            </div>
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-header">
                        <h3>QUY ĐỊNH</h3>
                        <div className="popup-hd-close" onClick={closePopup}>
                            &times;
                        </div>
                    </div>
                    <div className="popup-hd-content">
                        <p>
                            Mở kết quả theo thứ tự tự từ trái qua phải bắt đầu từ <b>Chục Ngàn,Ngàn, Trăm, Chục, Đơn vị</b>
                        </p>
                        <br></br>
                        <p>
                            NIKE nghiêm cấm mọi hình thức đầu tư gian lận, nếu bị phát hiện, hệ thống có quyền hạn thu hồi tất cả số tiền thưởng. Nếu có bất kì ý kiến hoặc kiến nghị nào, Vui lòng Lựa chọn "CSKH" ở dưới menu và để lại lời nhắn.
                        </p>
                        <br></br>
                        <p>Để đảm bảo hệ thống được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận được phần quà ngẫu nhiên may mắn từ hệ thống, khi rút tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như sau:</p>
                        <br></br>
                        <p>Nếu quý khách có bất kỳ ý kiến hoặc kiến nghị nào vui lòng lựa chọn mục "CSKH" và để lại lời nhắn.</p>
                        <br></br>
                        <p>
                            <b>Khi rút tiền cần thực hiện đóng phí duy trì theo hạn mức như sau:</b>
                        </p>
                        <ul>
                            <li> Hạn mức rút tài khoản dưới 500 triệu tương ứng với 20% phí</li>
                            <li> Hạn mức rút tài khoản trên 500 triệu tương ứng với 30% phí</li>
                        </ul>
                    </div>
                </div>
            )}
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            {showPopup && (
                <>

                    <div className="lottery-wrapper" onClick={resetForm}>
                    </div>
                    <form className="lottery-bet-input" onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className="chip-row"> */}
                        <div>
                            {/* <div className={`chip ${quantity == 100 ? "active" : ""}`} onClick={() => handleSetValue(100)}>
                            100
                        </div>
                        <div className={`chip ${quantity == 500 ? "active" : ""}`} onClick={() => handleSetValue(500)}>
                            500
                        </div>
                        <div className={`chip ${quantity == 1000 ? "active" : ""}`} onClick={() => handleSetValue(1000)}>
                            1000
                        </div>
                        <div className={`chip ${quantity == 10000 ? "active" : ""}`} onClick={() => handleSetValue(10000)}>
                            10000
                        </div>
                        <div className={`chip ${quantity == 20000 ? "active" : ""}`} onClick={() => handleSetValue(20000)}>
                            20000
                        </div> */}
                            <div className="bet__title" style={{ color: "#fff" }}>
                                SỐ LƯỢNG
                            </div>
                        </div>
                        <div className="bet-input">
                            <div style={{ cursor: "pointer" }} onClick={resetForm}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#fff"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{ position: "relative", top: "2px", margin: "0 3px 0 0" }}>
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                </svg>
                                Đóng lại
                            </div>
                            <div>
                                <input {...register("money", { required: true })} type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} name="money" />
                            </div>
                            <div>
                                <button type="submit">Xác nhận</button>
                            </div>
                        </div>
                        <div className="bet-input" style={{ color: "#fff", fontWeight: "bold" }}>
                            <div>Number: {bet ? bet.id_bet : "----"}</div>
                            <div>Số dư: {profile?.money}</div>
                        </div>
                    </form>
                </>
            )
            }
            <Footer profile={profile} />
        </>
    );
}
export default Game1;
